import { VFC } from 'react';

type Props = {
  src: string;
};

export const Image: VFC<Props> = (props) => (
  <div className="card">
    <div className="card-image">
      <figure className="image">
        <img src={props.src} alt="cute dog!" />
      </figure>
    </div>
  </div>
);
