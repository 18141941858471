import { VFC } from 'react';

type Props = {
  onFormSubmit: (breed: string) => void;
};

export const Form: VFC<Props> = (props) => {
  const handleSubmit = (event: any): void => {
    event.preventDefault();
    const { breed }: { breed: HTMLOptionElement } = event.target.elements;
    props.onFormSubmit(breed.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="field has-addons">
          <div className="control is-expanded">
            <div className="select is-fullwidth">
              <select name="breed" defaultValue="shiba">
                <option value="shiba">Shiba</option>
                <option value="akita">Akita</option>
              </select>
            </div>
          </div>
          <div className="control">
            <button type="submit" className="button is-dark">
              Reload
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
