import { render } from 'react-dom';
import { Header } from './Header';
import { Main } from './Main';
import { Footer } from './Footer';

import 'bulma/css/bulma.css';

render(
  <>
    <Header />
    <Main />
    <Footer />
  </>,
  document.querySelector('#content')
);
