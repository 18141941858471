import { VFC } from 'react';
import { Gallery } from './Main/Gallery';
import { Form } from './Main/Form';
import { useEffect, useState } from 'react';
import { fetchImages } from './util/api';

export const Main: VFC = () => {
  // useState(初期値)は配列で、[0]が現在の値,[1]が更新するための関数
  const [urls, setUrls] = useState(null);

  // 第2引数が[]のときは初回1回のみ
  useEffect(() => {
    fetchImages('shiba').then((urls) => {
      console.log(urls);
      setUrls(urls);
    });
  }, []);

  const reloadImages = (breed: string): void => {
    fetchImages(breed).then((urls) => {
      setUrls(urls);
    });
  };

  return (
    <main>
      <section className="section">
        <div className="container">
          <Form onFormSubmit={reloadImages} />
        </div>
      </section>
      <section className="section">
        <div className="container">
          <Gallery urls={urls} />
        </div>
      </section>
    </main>
  );
};
