import { VFC } from 'react';
import { Image } from './Gallery/Image';
import { Loading } from './Gallery/Loading';

type Props = {
  urls: string[] | null;
};

export const Gallery: VFC<Props> = (props) => {
  const { urls } = props;
  if (urls === null) {
    return <Loading />;
  }

  return (
    <div className="columns is-vcentered is-multiline">
      {urls.map((url) => (
        <div key={url} className="column is-3">
          <Image src={url} />
        </div>
      ))}
    </div>
  );
};
